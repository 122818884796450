import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import referenceTranslationsHu from "./referenceTranslations/hu/translationsHu";
import referenceTranslationsEn from "./referenceTranslations/en/translationsEn";
import referenceTranslationsDe from "./referenceTranslations/de/translationsDe";

i18n
  .use(LanguageDetector)

  .use(initReactI18next)

  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      hu: {
        translation: {
          aboutus: "Rólunk",
          career: "Karrier",
          certificates: "Tanúsítványaink",
          contact: "Kapcsolat",
          aboutText:
            "Egyedi szoftverfejlesztéssel és alkalmazás üzemeltetéssel foglalkozunk.",
          aboutText2:
            "Fő feladatunk, hogy az ügyfeleink digitalizációval kapcsolatos törekvéseit segítsük elő.",
          aboutText3:
            "Legyen az a konkrét, napi ügyvitel terén egy munkafolyamat digitális térbe való átültetése, vagy akár az ügyfél termékeinek előállítása során végzett automatizálás. Célunk, hogy átláthatóbb, gyorsabban végrehajtható folyamatokat, végeredményben nagyobb produktivitást és ezzel együtt nagyobb profitot biztosítsunk megrendelőinknek.",
          aboutText4:
            "Fontosnak tartjuk az adatalapú döntéstámogatás elérhetővé tételét ügyfeleink számára, magyarul, hogy a korábban rögzített adataink milyen következtetéseket engednek meg, illetve a jövőben melyik döntésnek mi lesz az eredménye? Csatlakoztunk a Mesterséges Intelligencia Koalícióhoz, hisszük, hogy ügyfeleink komplex problémáira jobb megoldásokat nyújthatnak a jövőbe mutató technológia adta lehetőségek.",
          aboutText5:
            "Több évtizedes tapasztalattal rendelkező kollégáink ma is a rengeteg technológiában otthonosan mozognak az IT világában, de soha nem fogunk hátra dőlni. Folyamatosan fejlesztjük a tudásunkat, hogy ez holnap is így legyen. :)",
          references: "Referenciák",
          partners: "Partnereink",
          openPosition: "Nyitott pozícióink",
          openPositionCSharp: "C# és/vagy C++ Fejlesztő, BIM, CAD területen",
          careerText: "",
          christmas:
            "Minden Kedves Partnerünknek Kellemes Karácsonyi Ünnepet És Sikerekben Gazdag Boldog Új Évet Kívánunk!",
          p5: "Szeretjük az ügyfeleink igényeit megvalósítani a szoftverfejlesztés különböző területein.",
          p6: "Főbb jellemzőink:",
          li15: "Full-stack fejlesztés a kezdetektől a webes és mobil frontend-ig",
          li16: "Eszközfejlesztés Windows vagy Linux környezetben",
          li17: "A különböző rendszerek egymással való integrálása és az előállított adatok elemzése",
          li18: "AWS, Azure, GIT, Docker, Scripting, Jira, UI Automation stb.",
          li19: "Koncepcionális és építészeti tanácsadás, adatmérnöki, műszaki PoC-k",
          li20: "Építőipari szoftverek, BIM, CAD, Archicad, AutoCad, AutoDesk Revit termékek területén szerzett tapasztalat",
          p9: "Összetett és még megoldatlan üzleti problémák azonosítása mesterséges intelligencia által támogatott megoldással.",
          p10: "Quick-Win megoldások meghatározása az ötletek alapján.",
          b3: "AIdeation workshop",
          p11: "Adatvezérelt megoldások, amelyek az üzleti érték és a megvalósíthatóság alapján prioritást élveznek.",
          ...referenceTranslationsHu,
        },
      },
      en: {
        translation: {
          aboutus: "About",
          career: "Career",
          certificates: "Our certificates",
          contact: "Contact",
          aboutText:
            "We deal with custom software development and application operation.",
          aboutText2:
            "Our main goal is to support our customers digitalisation efforts.",
          aboutText3:
            "Whether its a concrete, day-to-day management of a workflow in the digital space, or even automating the production of a customers products. Our goal is to create more transparent, faster processes, and ultimately, greater productivity and profitability for our customers.",
          aboutText4:
            "We believe it is important to make data-driven decision support available to our customers, in other words, what conclusions our previously recorded data allows us to draw, and what will be the outcome of which decision in the future? We joined the Artificial Intelligence Coalition, we believe, that forward-looking technology can provide better solutions to our clients complex problems.",
          aboutText5:
            "Our colleagues with decades of experience are still at home in the world of IT in the enormous amount of technology, but we will never sit back. We are constantly improving our knowledge to be so tomorrow. :)",
          references: "References",
          partners: "Our Partners",
          openPosition: "Open positions",
          openPositionCSharp: "C# and/or C++ Developer, BIM, CAD area",
          careerText:
            "NOTE: The above descriptions are in Hungarian, please contact us directly if you are interested.",
          christmas:
            "We wish all our dear partners a Merry Christmas and a successful Happy New Year!",
          watermeterTitle: "Water Meter Replacement Management System",
          watermeterAlTitle:
            "Easily and securely manageable administrative and mobile application",
          developmentTitle: "CAD/BIM development",
          developmentAlTitle:
            "Innovative CAD software add-ons, streamlining design and engineering tasks.",
          p5: "We like to implement the needs of our customers in various areas of software development",
          p6: "Our main features:",
          li15: "Full-stack development from start to web and mobile frontend",
          li16: "Device development in Windows or Linux environments",
          li17: "Integration of different systems with each other and analysis of the generated data",
          li18: "AWS, Azure, GIT, Docker, Scripting, Jira, UI Automation, etc.",
          li19: "Conceptual and architectural consulting, data engineering, technical PoCs",
          li20: "Experience in construction software, BIM, CAD, Archicad, AutoCad, AutoDesk Revit products",
          workshopTitle: "AIdeation workshop",
          workshopAlTitle:
            "Sharing knowledge/experience with technical colleagues/team leaders",
          p9: "Identifying complex and unsolved business problems with an AI-enabled solution.",
          p10: "Identifying quick-win solutions based on ideas.",
          b3: "AIdeation workshop",
          p11: "Data-driven solutions that are prioritised based on business value and feasibility.",
          generativAITitle: "Innosw AI Image Generator",
          generativAIText: "Turning sketches into stunning visual designs",
          ...referenceTranslationsEn,
        },
      },
      de: {
        translation: {
          aboutus: "Über uns",
          career: "Karriere",
          certificates: "Unsere Zertifikate",
          contact: "Kontakt",
          aboutText:
            "Wir beschäftigen uns mit kundenspezifischer Softwareentwicklung und Betrieb verschiedene Applikationen.",
          aboutText2:
            "Unsere Hauptaufgabe ist es, die Digitalisierungsbemühungen unserer Kunden voranzubringen.",
          aboutText3:
            "Ob es sich um die konkrete, tägliche Verwaltung eines Workflows im digitalen Raum handelt, oder sogar die Automatisierung der Produktion von Kundenprodukten. Unser Ziel ist es, transparentere und schnellere Prozesse zu schaffen, und letztendlich eine höhere Produktivität und Rentabilität für unsere Kunden.",
          aboutText4:
            "Wir glauben, dass es wichtig ist, unseren Kunden datengestützte Entscheidungshilfen zur Verfügung zu stellen, mit anderen Worten, welche Schlussfolgerungen wir aus unseren zuvor erfassten Daten ziehen können, und was wird das Ergebnis welcher Entscheidung in der Zukunft sein? Wir sind der Artificial Intelligence Coalition beigetreten, weil wir glauben, dass zukunftsweisende Technologie bessere Lösungen für die komplexen Probleme unserer Kunden bieten kann.",
          aboutText5:
            "Heute, unsere Kollegen mit jahrzehntelanger Erfahrung sind in der IT-Welt in zahllose Technologien zu Hause, aber wir werden uns niemals zurücklehnen. Wir verbessern ständig unser Wissen, dass dies morgen der Fall bleibt. :)",
          references: "Referenzen",
          partners: "Unsere Partner",
          openPosition: "Unsere offenen Stellen",
          openPositionCSharp: "C# und/oder C++ Developer, BIM, CAD Bereich",
          careerText:
            "HINWEIS: Die obigen Beschreibungen sind auf Ungarisch. Bei Interesse wenden Sie sich bitte direkt an uns.",
          christmas:
            "Wir wünschen all unseren lieben Partnern ein frohes Weihnachtsfest und ein erfolgreiches neues Jahr!",
          watermeterTitle: "Wasserzählerwechsel-Management-System",
          watermeterAlTitle: "Einfache und sichere Verwaltung und mobile App",
          developmentTitle: "CAD/BIM/AI-Entwicklung",
          developmentAlTitle:
            "Digitalisierung von Rohr- und Instrumentenplänen",
          p5: "Wir möchten die Bedürfnisse unserer Kunden in verschiedenen Bereichen der Softwareentwicklung erfüllen.",
          p6: "Wesentliche Merkmale:",
          li15: "Full-Stack-Entwicklung vom Start bis zum Web- und Mobile-Fronten",
          li16: "Geräteentwicklung in Windows- oder Linux-Umgebungen",
          li17: "Integration verschiedener Systeme und Analyse der erzeugten Date",
          li18: "AWS, Azure, GIT, Docker, Skripting, Jira, UI Automation usw.",
          li19: "Konzeptionelle und architektonische Beratung, Data Engineering, technische PoCs",
          li20: "Erfahrung mit Bausoftware, BIM, CAD, Archicad, AutoCad, AutoDesk Revit Produkten",
          workshopTitle: "AIdeation Workshop",
          workshopAlTitle:
            "Austausch von Wissen/Erfahrungen mit technischen Kollegen/Teamleitern",
          p9: "Identifizierung komplexer und ungelöster Geschäftsprobleme mit einer KI-gestützten Lösung.",
          p10: "Identifizierung von Quick-Win-Lösungen auf der Grundlage von Ideen.",
          b3: "AIdeation Workshop",
          p11: "Datengestützte Lösungen, die auf der Grundlage von Geschäftswert und Machbarkeit priorisiert werden.",
          generativAITitle: "Innosw AI Image Generator",
          generativAIText:
            "Skizzen in beeindruckende visuelle Designs verwandeln",
          ...referenceTranslationsDe,
        },
      },
    },
  });

export default i18n;
