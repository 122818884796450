import React from 'react';
import cDeveloper from '../../doc/karrier/CSHARP_CPP_DeveloperBIM_CAD.pdf';
import pythonDeveloper from '../../doc/karrier/PythonBackendDeveloper.pdf';
import { useTranslation } from 'react-i18next';


export default function Career() {

    const { t } = useTranslation();
    return (
        <div id="karrier" className="w3-padding-32 w3-content w3-text-grey" style={{marginBottom:64}}>
            <h2>{t('career')}</h2>
            <hr className="w3-opacity" />
            <h3 id="nyitott">{t('openPosition')}</h3>
            <div className="w3-cell-row">
                
            </div>
            <h4>{t('careerText')}</h4>
        </div>
    )
}
