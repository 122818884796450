const aiWorkshopTranslationsHu = {
  aiWorkshopTitle: "AIdeation Workshop",
  aiWorkshopLink:
    "🌟 Kattints ide, és jelentkezz workshopjainkra az AIdeation weboldalán! 🌟",
  aiWorkshopP1:
    "Az alábbiakban szeretnénk bemutatni Önnek az “AIdeation workshop” szolgáltatásunkat.",
  aiWorkshopLi1: "Az AI a jelen, nem a jövő",
  aiWorkshopLi2:
    "Szoftverfejlesztő cégként széleskörű tapasztalattal rendelkezünk ügyfeleink támogatásában az AI vezérelte megoldások alkalmazásában, illetve adat alapú döntés támogató rendszerek kiépítésében.",
  aiWorkshopLi3: "Kinek ajánljuk a workshopot?",
  aiWorkshopLi3_li1:
    "Kis cégektől egészen a multinacionális nagyvállalatokig bárkinek, akik nem, vagy csak kevésbé használják az AI-t mindennapjaik során",
  aiWorkshopLi3_li2:
    "Cégeknek, akik hisznek az AI adta lehetőségekben, csak nem tudják, hogyan fogjanak hozzá, hogyan kezdjék el beintegrálni a munkafolyamataikba",

  aiWorkshopLi4: "Hogyan zajlik egy ilyen workshop? Milyen témákat érintünk?",
  aiWorkshopLi4_li1:
    "Az eddigi alkalmak interaktív formában zajlottak, ügyfeleink rengeteg kérdést tettek fel, amelyek kivétel nélkül tisztázásra kerültek",
  aiWorkshopLi4_li2:
    "Milyen előnyökkel és hátrányokkal jár egy AI alapú megoldás",
  aiWorkshopLi4_li3:
    "Különbség az adat alapú és a tapasztalat alapú gondolkodásmód között",
  aiWorkshopLi4_li4: "Miért most érdemes az AI-be időt és energiát fektetni",
  aiWorkshopLi4_li5: "Különbség a jó és a rossz adat között",
  aiWorkshopLi4_li6: "Mire érdemes és mire nem érdemes AI-t használni",
  aiWorkshopLi4_li7: "Neurális hálók",
  aiWorkshopLi4_li8:
    "Egy AI project teljes folyamata az információ gyűjtéstől a valódi használatig",
  aiWorkshopLi4_li9:
    "Hogyan lehet elindulni az adat alapú gondolkodás és döntés támogató rendszerek világában",

  aiWorkshopLi5: "Mennyire személyre szabott ez a workshop?",
  aiWorkshopLi5_li1:
    "Az alkalom előtt felmérjük a résztvevők tudás szintjét  egy rövid kérdőív segítségével, majd ez alapján szabjuk személyre az előadásokat",
  aiWorkshopLi5_li2:
    "Igyekszünk a célközönség területéről példákat hozni (korábban például építőipari példákat hoztunk)",

  aiWorkshopLi6:
    "Hisszük, hogy egy ilyen workshop akkor igazán hatékony, ha nem csak a tudás kerül átadásra, de ötletek is születnek arra, hogy hogyan lehet mindezt alkalmazni, ezért a workshop két részből áll, egy 1 napos tudás megosztásból és egy 0,5 napos ötletelésből a cég profiljába vágóan",

  aiWorkshopLi7:
    "Mire való az ötletelésre szánt 0,5 nap és mi történik a megszületett ötletekkel?",
  aiWorkshopLi7_li1:
    "Priorizáljuk az ötleteket üzleti haszon és megvalósíthatóság szerint, az adatalapú döntéstámogató rendszerek kiépítése érdekében",
  aiWorkshopLi7_li2:
    "Jogosan kérdezhetik, hogy ki fogja megvalósítani az ötleteket. Bár a workshop elsődleges célja nem ez, igény esetén mi is tudunk segíteni egy konkrét, kiforrott ötlet megvalósításában",

  aiWorkshopP2:
    "Amennyiben szeretné kiaknázni az AI megoldások nyújtotta lehetőségeket, vagy csak egyszerűen kíváncsi, keressen bátran az elérhetőségeink egyikén.",
};

export default aiWorkshopTranslationsHu;
