import gisTranslationsEn from "./gisTranslationsEn";
import cadBimTranslationsEn from "./cadBimTranslationsEn";
import objectDetectionTranslationsEn from "./objectDetectionTranslationsEn";
import aiWorkshopTranslationsEn from "./aiWorkshopTranslationsEn";
import waterMeterTranslationsEn from "./waterMeterTranslationsEn";
import simulationTranslationsEn from "./simulationTranslationsEn";

const referenceTranslationsEn = {
  generativAICardTitle: "Innosw AI Image Generator",
  generativAICardText:
    "Turn sketches, simple 3D views into stunning visual designs",
  objectDetectionCardTitle: "AI-based object detection",
  objectDetectionCardText: "Detect any symbol using customizable AI models",
  cadBimCardTitle: "CAD/BIM developments",
  cadBimCardText:
    "Increase design and engineering efficiency through customized add-ons and automation",
  simulationCardTitle: "3D simulations",
  simulationCardText:
    "Simulations and calculations on virtual 3D models in Unity3D",
  gisCardTitle: "Geographic Information System developments",
  gisCardText:
    "Interpret, process and complete large amounts of geographical data",
  watermeterCardTitle: "Water Meter Replacement Management System",
  watermeterCardText: "Full workflow management on web and mobile platforms",
  workshopCardTitle: "AIdeation workshop",
  workshopCardText:
    "Developing skills in applying artificial intelligence in a day and a half, for teams",

  ...cadBimTranslationsEn,
  ...gisTranslationsEn,
  ...objectDetectionTranslationsEn,
  ...aiWorkshopTranslationsEn,
  ...waterMeterTranslationsEn,
  ...simulationTranslationsEn,
};

export default referenceTranslationsEn;
