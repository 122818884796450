const aiWorkshopTranslationsDe = {
  aiWorkshopTitle: "AIdeation Workshop",
  aiWorkshopLink:
    "🌟 Klicken Sie hier, um sich für unsere Workshops auf der AIdeation-Website anzumelden! 🌟",
  aiWorkshopP1:
    "Wir möchten unsere Erfahrungen mit unserem Service 'AIdeation Workshop' mit Ihnen teilen.",
  aiWorkshopLi1: "KI ist heute, nicht morgen.",
  aiWorkshopLi2:
    "Als Softwareentwicklungsunternehmen haben wir verschiedene Erfahrungen mit unseren Kunden gemacht, die neugierig darauf sind, die Vorteile von KI-basierten und datengesteuerten Lösungen zu nutzen, um mit anderen zu konkurrieren. Sie sind der Meinung, dass es sich lohnt, in KI zu investieren, und wir wollten sie bei dieser Entscheidung unterstützen. Aus diesem Grund wurde unser 1,5-tägiger Workshop ins Leben gerufen.",
  aiWorkshopLi3: "Wem empfehlen wir diesen Workshop?",
  aiWorkshopLi3_li1:
    "Von kleineren Unternehmen bis hin zu multinationalen Konzernen, die noch keine KI-Lösungen einsetzen. Unternehmen, die an das Potenzial glauben, aber nicht wissen, wie und wo sie anfangen sollen.",

  aiWorkshopLi4:
    "Wie sind unsere bisherigen Workshops gelaufen? Welche Themen behandeln wir?",
  aiWorkshopLi4_li1:
    "Die Veranstaltungen waren interaktiv mit vielen Fragen, die zwischen den Teilnehmern geklärt wurden.",
  aiWorkshopLi4_li2: "Welche Vor- und Nachteile hat die KI?",
  aiWorkshopLi4_li3:
    "Unterschied zwischen datengesteuerter und instinktiver Mentalität",
  aiWorkshopLi4_li4:
    "Warum jetzt der beste Zeitpunkt ist, um in KI zu investieren.",
  aiWorkshopLi4_li5: "Was sind gute und schlechte Daten?",
  aiWorkshopLi4_li6: "Wann sollte man AI einsetzen und wann nicht?",
  aiWorkshopLi4_li7: "Neuronale Netze",
  aiWorkshopLi4_li8:
    "Vollständiger Arbeitsablauf eines KI-Projekts von Anfang bis Ende",
  aiWorkshopLi4_li9:
    "Einführung einer datengesteuerten Mentalität und von Systemen oder Instrumenten zur Unterstützung von Entscheidungen",

  aiWorkshopLi5: "Wie personalisiert ist der Workshop?",
  aiWorkshopLi5_li1:
    "Die Teilnehmer müssen einen Fragebogen ausfüllen, der uns hilft, die Vorkenntnisse zu ermitteln und die Themen so anzupassen, dass sie für unsere Kunden am besten geeignet sind.",
  aiWorkshopLi5_li2:
    "Wir versuchen, Beispiele aus den spezifischen Bereichen des Publikums zu sammeln.",

  aiWorkshopLi6:
    "Wir glauben, dass ein Workshop wie dieser nützlich ist, wenn nicht nur das Wissen mit den Teilnehmern geteilt wird, sondern auch einige Ideen entstehen, die die Produktivität Tag für Tag steigern können.",
  aiWorkshopLi6_li1:
    "Deshalb haben wir beschlossen, unseren 1-tägigen Workshop zum Wissensaustausch mit einem Ideen-Workshop zu verbinden (1 Tag Wissensaustausch + 0,5 Tage Ideenfindung).",

  aiWorkshopLi7_li1:
    "Das Ziel des Ideation-Teils ist es, in datengesteuerten Lösungen zu denken und Ideen auf der Grundlage von Geschäftsnutzen und Machbarkeit zu priorisieren.",
  aiWorkshopLi7_li2:
    "Man könnte sich fragen: Wer wird die Lösungen, die auf diesen Ideen basieren, umsetzen? Obwohl dies nicht das primäre Ziel unseres Workshops ist, können wir in einigen Fällen auch dabei helfen.",

  aiWorkshopP2:
    "Wenn Sie Ihre KI-Fähigkeiten ausbauen möchten oder einfach nur neugierig auf etwas Neues sind, zögern Sie nicht, uns bei einer unserer Erreichbarkeiten zu kontaktieren.",
};

export default aiWorkshopTranslationsDe;
